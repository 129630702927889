.form-container {
  margin-bottom: 6rem;
}

.form-table-container {
  margin: 3em -26px 0;
  -ms-overflow-style: auto;
  .form-control {
    min-width: 42px;
    text-align: center;
  }
}

.form-label {
  display: block;
}

label.error {
  color: #f77;
}

form {
  .removing {
    background: #eee;
  }
}

.display-inline {
  display: inline-block;
}