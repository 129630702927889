.login-content {
    overflow: hidden;
    height: 100%;
    background-color: #2E404E;
}

.lc-block {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 500px;
    padding: 35px 55px 35px;
    display: inline-block;
    margin-top: 25vh;
    vertical-align: middle;
    position: relative;
    
    &.toggled {
        .animated(fadeInUp, 300ms);
        z-index: 10;
    }

    @media (max-width: @screen-xs-max) {
        margin-top: 50px;
        padding: 35px 40px 35px 5px;
        width: ~"calc(100% - 60px)";

        .logo {
          margin-right: -35px;
        }
    }

    .checkbox {
        margin: 5px 0 0 42px;
        text-align: left; 
    }

    .logo {
      text-align: center;
      img {
        max-height: 100px;
        margin: 0 auto 30px;
      }
    }

    &:not(.lcb-alt) {
        .btn-login {
            top: 50%;
            margin-top: -25px; 
            right: -25px; 
        }
    }
}

.login-navigation {     
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0%;
    bottom: -45px;

    & > li {
        display: inline-block;
        margin: 0 2px;
        cursor: pointer;
        vertical-align: top;
        color: #fff;
        line-height: 16px;
        min-width: 20px;
        min-height: 20px;
        text-transform: uppercase;
        .backface-visibility(hidden);

        & > span {
            .opacity(0);
        }

        &:not(:hover) {
            font-size: 0px;
            border-radius: 10px;
        }

        &:hover {
            border-radius: 10px;
            padding: 2px 8px;
            font-size: 12px;

            & > span {
                .opacity(1);
            } 
        }

        a {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }

    }
}
 
.lcb-alt {
    padding: 70px 55px 60px;
    
    .btn-login {
        bottom: -25px;
        left: 50%;
        margin-left: -25px;
    }
    
    .login-navigation {
        bottom: -75px;
    }
}

.lcb-user {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.18);
}

body.login-content {
    text-align: center;
    
    &:after {
        content: "";
        vertical-align: middle; 
        display: inline-block;
        width: 1px;
        height: 100%;
    }
}