.bootgrid-table th:active, .bootgrid-table th:hover {
  background: #9FA8C1 !important;
  //background: #607D8B;
  border-width: 2px 0;
  border-style: solid none;
  border-color: #FDBE33 #000 #D77206;
  border-radius: 1px;
  /* background: linear-gradient(#F3AE0F, #E38916) #E38916; */
  transition: 0.2s;
}

.table {
  > thead:first-child {
    > tr:first-child {
      > th {
        background: rgba(203, 203, 208, 0.74);
        border-top: 0;
      }
      > th.sortable:hover {
        cursor: pointer !important;
        > a {
          cursor: inherit !important;
        }
      }
      > th:hover {
        cursor: not-allowed;
        > a {
          cursor: inherit !important;
        }
      }
    }
  }
}

.bootgrid-table td {
  white-space: normal;
}

.bootgrid-table th > .column-header-anchor {
  cursor: pointer !important;
}