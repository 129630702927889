.pagination {
    border-radius: 0;
    
    & > li {
        margin: 0 2px;
        display: inline-block;
        vertical-align: top;
        
        & > a,
        & > span {
            border-radius: 50% !important;
            padding: 0;
            width: 40px;
            height: 40px;
            line-height: 38px;
            text-align: center;
            font-size: 14px;
            z-index: 1;
            position: relative;
            cursor: pointer;
            
            & > .zmdi {
                font-size: 22px;
                line-height: 39px;
            }
        }
         
        &.disabled {
            .opacity(0.5);
        }  
    }
} 

/*
 * Listview Pagination
 */
.lv-pagination {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    border-top: 1px solid #F0F0F0;
    margin-top: 0;
    margin-bottom: 0;
}

/*
 * Pager
 */
.pager li > a, .pager li > span {
    padding: 5px 10px 6px; 
    color: @pagination-color;
}
