.invoice {
    min-width: 1100px;
    max-width: 1170px;
}

.i-logo {
        width: 150px;
}

.i-table {
    .highlight { 
        background-color: #eee; 
        border-bottom: 1px solid darken(#eee, 3%); 
    }
    
    td.highlight {
        font-size: 14px;
        font-weight: 500;
    }
}
