.relation-form {

    .table {
        & > thead > tr,
        & > tbody > tr,
        & > tfoot > tr {
            & > th, & > td {
                padding: 15px;
                &:first-child {
                    padding-left: 10px;
                }

                &:last-child {
                    padding-right: 10px;
                }

            }
        }

        .table {
            margin-top: -15px;
            background: none;
        }
    }
}