.wi-moon-new:before {content: @moon-new;}
.wi-moon-waxing-crescent-1:before {content: @moon-waxing-crescent-1;}
.wi-moon-waxing-crescent-2:before {content: @moon-waxing-crescent-2;}
.wi-moon-waxing-crescent-3:before {content: @moon-waxing-crescent-3;}
.wi-moon-waxing-crescent-4:before {content: @moon-waxing-crescent-4;}
.wi-moon-waxing-crescent-5:before {content: @moon-waxing-crescent-5;}
.wi-moon-waxing-crescent-6:before {content: @moon-waxing-crescent-6;}
.wi-moon-first-quarter:before {content: @moon-first-quarter;}
.wi-moon-waxing-gibbous-1:before {content: @moon-waxing-gibbous-1;}
.wi-moon-waxing-gibbous-2:before {content: @moon-waxing-gibbous-2;}
.wi-moon-waxing-gibbous-3:before {content: @moon-waxing-gibbous-3;}
.wi-moon-waxing-gibbous-4:before {content: @moon-waxing-gibbous-4;}
.wi-moon-waxing-gibbous-5:before {content: @moon-waxing-gibbous-5;}
.wi-moon-waxing-gibbous-6:before {content: @moon-waxing-gibbous-6;}
.wi-moon-full:before {content: @moon-full;}
.wi-moon-waning-gibbous-1:before {content: @moon-waning-gibbous-1;}
.wi-moon-waning-gibbous-2:before {content: @moon-waning-gibbous-2;}
.wi-moon-waning-gibbous-3:before {content: @moon-waning-gibbous-3;}
.wi-moon-waning-gibbous-4:before {content: @moon-waning-gibbous-4;}
.wi-moon-waning-gibbous-5:before {content: @moon-waning-gibbous-5;}
.wi-moon-waning-gibbous-6:before {content: @moon-waning-gibbous-6;}
.wi-moon-third-quarter:before {content: @moon-third-quarter;}
.wi-moon-waning-crescent-1:before {content: @moon-waning-crescent-1;}
.wi-moon-waning-crescent-2:before {content: @moon-waning-crescent-2;}
.wi-moon-waning-crescent-3:before {content: @moon-waning-crescent-3;}
.wi-moon-waning-crescent-4:before {content: @moon-waning-crescent-4;}
.wi-moon-waning-crescent-5:before {content: @moon-waning-crescent-5;}
.wi-moon-waning-crescent-6:before {content: @moon-waning-crescent-6;}
.wi-moon-alt-new:before {content: @moon-alt-new;}
.wi-moon-alt-waxing-crescent-1:before {content: @moon-alt-waxing-crescent-1;}
.wi-moon-alt-waxing-crescent-2:before {content: @moon-alt-waxing-crescent-2;}
.wi-moon-alt-waxing-crescent-3:before {content: @moon-alt-waxing-crescent-3;}
.wi-moon-alt-waxing-crescent-4:before {content: @moon-alt-waxing-crescent-4;}
.wi-moon-alt-waxing-crescent-5:before {content: @moon-alt-waxing-crescent-5;}
.wi-moon-alt-waxing-crescent-6:before {content: @moon-alt-waxing-crescent-6;}
.wi-moon-alt-first-quarter:before {content: @moon-alt-first-quarter;}
.wi-moon-alt-waxing-gibbous-1:before {content: @moon-alt-waxing-gibbous-1;}
.wi-moon-alt-waxing-gibbous-2:before {content: @moon-alt-waxing-gibbous-2;}
.wi-moon-alt-waxing-gibbous-3:before {content: @moon-alt-waxing-gibbous-3;}
.wi-moon-alt-waxing-gibbous-4:before {content: @moon-alt-waxing-gibbous-4;}
.wi-moon-alt-waxing-gibbous-5:before {content: @moon-alt-waxing-gibbous-5;}
.wi-moon-alt-waxing-gibbous-6:before {content: @moon-alt-waxing-gibbous-6;}
.wi-moon-alt-full:before {content: @moon-alt-full;}
.wi-moon-alt-waning-gibbous-1:before {content: @moon-alt-waning-gibbous-1;}
.wi-moon-alt-waning-gibbous-2:before {content: @moon-alt-waning-gibbous-2;}
.wi-moon-alt-waning-gibbous-3:before {content: @moon-alt-waning-gibbous-3;}
.wi-moon-alt-waning-gibbous-4:before {content: @moon-alt-waning-gibbous-4;}
.wi-moon-alt-waning-gibbous-5:before {content: @moon-alt-waning-gibbous-5;}
.wi-moon-alt-waning-gibbous-6:before {content: @moon-alt-waning-gibbous-6;}
.wi-moon-alt-third-quarter:before {content: @moon-alt-third-quarter;}
.wi-moon-alt-waning-crescent-1:before {content: @moon-alt-waning-crescent-1;}
.wi-moon-alt-waning-crescent-2:before {content: @moon-alt-waning-crescent-2;}
.wi-moon-alt-waning-crescent-3:before {content: @moon-alt-waning-crescent-3;}
.wi-moon-alt-waning-crescent-4:before {content: @moon-alt-waning-crescent-4;}
.wi-moon-alt-waning-crescent-5:before {content: @moon-alt-waning-crescent-5;}
.wi-moon-alt-waning-crescent-6:before {content: @moon-alt-waning-crescent-6;}