.panel {
    box-shadow: none;
    border: 0;
}

.panel-heading {
    padding: 0;
}

.panel-title {
    & > a {
        padding: 10px 15px;
        display: block;
        font-size: 13px;
    }
}
 
.panel-collapse {
    .panel-heading {
        position: relative;
        
        .panel-title {
            & > a {
                padding: 8px 5px 16px 30px;
                color: #000;
                position: relative;
                
                &:after,
                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    content: "";
                    .transition(all);
                    .transition-duration(300ms);
                    .backface-visibility(hidden);
                }
                
                &:after {
                    .scale(0);
                }
            }
        }
        
        &:not(.active) .panel-title > a {
            &:before {
                background: #eee;
            }
        }
        
        &:before,
        &:after {
            font-family: @font-icon-md;
            font-size: 17px;
            position: absolute;
            left: 0;
            .transition(all);
            .transition-duration(300ms);
            .backface-visibility(hidden);
            top: 4px;
        }
        
        &:before {
            content: "\f278";
            .scale(1);
        }
        
        &:after {
            .scale(0);
            content: "\f273";
        }
        
        &.active {
            .panel-title > a {
                &:after {
                    .scale(1);
                }
            }
            
            &:before {
                .scale-rotate(0, -90deg);
            }
            
            &:after {
                .scale(1);
            }
        }
    }
    
    
    .panel-body {
        border-top: 0 !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.panel-collapse-color(@color) {
    .panel-collapse {
        .panel-heading {
            &.active .panel-title > a {
                &:after {
                    background: @color;
                }
            }
        }
    }
}

.panel-group {
    &:not([data-collapse-color]) {
        .panel-collapse-color(@m-blue);
    }
    
    &[data-collapse-color="red"] {
        .panel-collapse-color(@m-red);
    }
    
    &[data-collapse-color="green"] {
        .panel-collapse-color(@m-green);
    }
    
    &[data-collapse-color="amber"] {
        .panel-collapse-color(@m-amber);
    }
    
    &[data-collapse-color="teal"] {
        .panel-collapse-color(@m-teal);
    }
    
    &[data-collapse-color="black"] {
        .panel-collapse-color(@m-black);
    }
    
    &[data-collapse-color="cyan"] {
        .panel-collapse-color(@m-cyan);
    }
}
