.btn-old-school() {
  border-radius: 0;
  padding: 10px 15px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 5px solid #000;
    border-left-color: rgba(255, 255, 255, 0.3);
    border-top-color: rgba(255, 255, 255, 0.5);
    border-right-color: rgba(0, 0, 0, 0.3);
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
  &:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
    z-index: -1;
  }
  &:not(.btn-link) {
    box-shadow: none;
  }
}

#header {
  background-color: #8098b2;
}

#sidebar {
  background-image: url('../img/old_school/bg.png');
}

.btn-primary {
  background-color: #7ca2cc;
  &,
  &:hover,
  &:focus,
  &:active {
    &:hover {
      background-color: #639fe2;
    }
  }
}

.btn.waves-effect {
  .btn-old-school();
}

.bootgrid-header {

  .search {
    border-width: 2px;
  }

  .actions {
    .btn {
      .btn-old-school();
      &-default {
        color: #333;
        background-color: #ccc;
        border-color: transparent !important;
        &:hover {
          background-color: #eee !important;
          border-color: transparent !important;
        }
      }
    }
    .btn-group {
      border: none;
      .btn {
        .btn-old-school();
        color: #333;
        background-color: #ccc;
        border-color: transparent !important;
        height: auto;
        box-shadow: none !important;
        &:hover {
          background-color: #eee !important;
          border-color: transparent !important;
        }
      }
    }
  }
}

.pagination > li > a, .pagination > li > span {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 4px solid;
    border-radius: 50%;
    border-left-color: rgba(255, 255, 255, 0.3);
    border-top-color: rgba(255, 255, 255, 0.5);
    border-right-color: rgba(0, 0, 0, 0.3);
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
}

.form-control {
  border-width: 2px;
}

.bootstrap-select > .btn-default {
  border-width: 2px;
}

.bootstrap-select .dropdown-menu {
  border-width: 2px;
}

.bootstrap-select .bs-searchbox button.bs-reference-create {
  display: none;
}

.checkbox .input-helper:before,
.radio .input-helper:before {
  border-width: 2px;
}

.checkbox {
  .input-helper {
    &:before {
      border-width: 2px;
    }
    &:after {
      width: 16px;
      height: 9px;
      border-bottom-width: 3px;
      border-left-width: 3px;
      left: 1px;
      top: 2px;
    }
  }
}

.radio {
  .input-helper {
    &:before {
      border-width: 2px;
    }
    &:after {
      width: 9px;
      height: 9px;
      top: 4px;
      left: 5px;
    }
  }
}

.main-menu {
  margin: 20px;
  & > li {
    position: relative;
    margin-bottom: 4px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 5px solid #000;
      border-left-color: rgba(255, 255, 255, 0.3);
      border-top-color: rgba(255, 255, 255, 0.5);
      border-right-color: rgba(0, 0, 0, 0.3);
      border-bottom-color: rgba(0, 0, 0, 0.5);

      z-index: -1;
      background-color: #ddd;
    }
    & > a {
      &:hover {
        background-color: rgba(247, 247, 247, 0.3);
      }
    }
  }
}

.sub-menu {
  ul {
    & > li {
      & > a {
        color: #444;
      }
    }
  }
}

.ui-selected {
  background: #8098b2;
}

.ui-selecting {
  background: rgba(128, 152, 178, 0.5);
}