.pricing-policies-form {

    margin-top: 3rem;

    .product-group-name {
        width: 15rem;
        vertical-align: middle;
        font-size: 2rem;
    }

    .table {

        .table {
            & > thead > tr,
            & > tbody > tr,
            & > tfoot > tr {

                &:first-child {
                    & > th, & > td {
                        border-top: none;
                    }
                }

                & > th, & > td {

                    &.price-policy-actions {
                        text-align: center;
                        .btn {
                            margin-top: 2px;
                        }
                    }

                    .form-group {
                        margin-bottom: 0;
                    }

                }
            }
        }
    }
}