.bootgrid-footer .infoBar, .bootgrid-header .actionBar {
    text-align: left; 
}

.bootgrid-footer .search, .bootgrid-header .search {
    vertical-align: top;
}

.bootgrid-footer {
    .pagination .prev,
    .pagination .next {
        display: none;
    }
}

.bootgrid-header {
    padding: 0 25px 10px;
    
    .search { 
        border: 1px solid #e0e0e0;
        
        .form-control, .input-group-addon {
            border: 0;
        }
        
        .glyphicon-search {
            vertical-align: top;
            padding: 9px 10px 0;

            &:before {
                content: "\f1c3";
                font-family: @font-icon-md;
                font-size: 17px;
                vertical-align: top;
                line-height: 100%;
            }
        }
        
        @media (min-width: @screen-xs-min) {
            width: 300px;   
        }
        
        @media (max-width: @screen-xs-min) {
            width: 100%;
            padding-right: 90px;
        }
    }
    
    .actions {
        box-shadow: none;

        .btn {
          padding: 10px 12px;
          margin-right: 2px;
        }

        .btn-group {
            border: 1px solid #e0e0e0;
                
            .btn {
                height: 35px;
                box-shadow: none !important;
                background: transparent; 
            }
            
            .dropdown-menu {
                padding: 0;
                
                .dropdown-item {
                    padding: 12px 15px 10px !important;
                }

                .checkbox {
                    padding: 5px 20px;

                    .dropdown-item {
                        padding: 0 0 0 27px !important;

                        &:hover {
                            background-color: #fff !important;
                        }
                    }
                }
                
                @media (min-width: @screen-sm-min) {
                    left: 0;
                    .transform-origin(top left);
                    margin-top: 1px;
                }
            } 
            
            .caret {
                display: none;
            }
            
            .zmdi {
                line-height: 100%;
                font-size: 18px;
                vertical-align: top;
                .transition(all);
                .transition-duration(250ms);
            }
            
            &.open {
                .zmdi {
                    .rotate(90deg);
                }
            }
        }
        
        @media (max-width: @screen-xs-min) {
            position: absolute;
            top: 0;
            right: 15px;
        }
    }
}

.bootgrid-table {

  th {
    & > .column-header-anchor {
      & > .icon {
        top: 0px;
        font-size: 20px;
        line-height: 100%;
      }
    }
  }

  td {
    vertical-align: middle !important;

    &.td-muted {
      opacity: .35;
    }
  }
}

.bootgrid-footer {    
    .col-sm-6 {
        padding: 10px 30px 20px;
        
        @media (max-width: @screen-sm-min) {
            text-align: center;
        }
    }
    
    .infoBar {
        @media (max-width: @screen-sm-min) {
            display: none;
        }
        
            .infos {
                border: 1px solid #EEE;
                display: inline-block;
                float: right;
                padding: 7px 30px;
                font-size: 12px;
                margin-top: 5px;
            }
    }
}

.select-cell {
    .checkbox {
        margin: 0;
    }
}

.command-edit, .command-delete {
    background: #fff;
}