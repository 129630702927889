.wall-attrs {
    margin-bottom: 0;
}

.wa-stats {
    float: left;
    
    & > span {
        margin-right: -1px;
        padding: 7px 12px;
        border: 1px solid #E0E0E0;
        float: left;
        font-weight: 500;
        
        &.active {
            color: @m-green;
        }
        
        &:first-child {
            border-radius: 2px 0 0 2px;
        }
        
        &:last-child {
            border-radius: 0 2px 2px 0;
        }
        
        & > i { 
            line-height: 100%;
            vertical-align: top;
            position: relative;
            top: 2px;
            font-size: 15px;
            margin-right: 2px;
        }
    }    
}

.wa-users {
    float: right;
    padding: 0 !important;
    margin-right: -5px;
    
    & > a {
        display: inline-block;
        margin-left: 2px;
        
        & > img {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            
            &:hover {
                .opacity(0.85);
            }
        }
    }
}

.wcc-inner { 
    border: 1px solid #E4E4E4;
    padding: 10px 15px;
    resize: none;
    border-radius: 2px;
    background: #fff;
    color: #9A9A9A;
    cursor: pointer;
} 

.wcci-text {
    border: 0;
    display: block;
    width: 100%;
    resize: none;
    padding: 0;
}

.wall-comment-list {
    padding: 20px;
    background: @app-gray;
    
    .media {
        position: relative;
        
        &:hover {
            .actions {
                display: block;
            }
        }
    }
    
    .actions {
        display: none; 
        position: absolute;
        right: -20px;
        top: -1px;
    }
}

.wcl-list + .wcl-form {
    margin-top: 25px; 
}

.wp-text {
    border: 0;
    padding: 0;
    display: block;
    width: 100%;
    resize: none;
}

.wp-media {
    background: @app-gray;
    border: 1px solid #E4E4E4;
    padding: 12px 15px;
    margin-top: 25px;
    text-align: center;
}

.wpb-actions {
    background: @app-gray;
    margin: 0;
    padding: 10px 20px;
    
    & > li:not(.pull-right) {
        float: left;
    }
}

.wall-attr-types(@color) {
    color: @color;
    
    &:hover {
        color: darken(@color, 5%);
    }
}


[data-wpba="image"] { .wall-attr-types(@m-green); }
[data-wpba="video"] { .wall-attr-types(@m-orange); }
[data-wpba="link"] { .wall-attr-types(@m-cyan); }
 
.wpba-attrs {
    & > ul {
        & > li {
            padding: 0;
            margin-right: 5px;

            & > a {
                display: block;
                width: 22px;

                & > i {
                    font-size: 20px;
                }
            } 
            
            &.active i {
                color: #333;
            }
        }
    } 
}

.wall-img-preview {
    text-align: center;
    
    @media screen and (min-width: @screen-sm-min) {
        margin: 0 -23px 20px;
    }

    @media screen and (max-width: @screen-sm-max) {
        margin: 0 -16px 20px;
    }

    .wip-item {
        display: block;
        float: left;
        position: relative;
        overflow: hidden;
        border: 2px solid #fff;
        .bg-cover-inline();
        
        & > img { 
            display: none;
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ div {
            width: 50%;
            padding-bottom: 40%;
        }
        
        &:first-child:nth-last-child(3), 
        &:first-child:nth-last-child(3) ~ div,
        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ div:not(:last-child),
        &:first-child:nth-last-child(5),
        &:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
        &:first-child:nth-last-child(6),
        &:first-child:nth-last-child(6) ~ div,
        &:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3) {
            width: 33.333333%;
            padding-bottom: 30%;
        }

        &:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
            width: 50%;
            padding-bottom: 40%;
        }
        
        &:first-child:nth-last-child(7),
        &:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
        &:first-child:nth-last-child(n+8),
        &:first-child:nth-last-child(n+8) ~ div {
            width: 25%;
            padding-bottom: 22%;
        }

        &:only-child,
        &:first-child:nth-last-child(4) ~ div:nth-child(4) {
            width: 100%;
            padding-bottom: 50%;
        }
    }
}