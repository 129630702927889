.table {
    .form-group { position: relative; }
    .green,
    .red { font-weight: bold; }

    .green { color: green; }
    .red { color: red; }

    .th-number { width: 7rem; }
    .th-datepicker { width: 12rem; }
    .th-comment { width: 15rem }
    .th-checkbox { width: 8rem; }
    .td-actions { padding-right: 0 !important; }
    .td-removed {
        padding-top: 19px !important;
        padding-bottom: 18px !important;
        text-align: center;
        font-weight: bold;
    }

    .toggle-switch { margin-top: .85rem; }

    input[type="number"] { padding-right: .25rem; }
}