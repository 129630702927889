.tab-nav {
    list-style: none;
    padding: 0;
    white-space: nowrap;
    margin: 0;
    overflow: auto;
    box-shadow: inset 0 -2px 0 0 #eee;
    width: 100%;
        
    li {
        display: inline-block;
        vertical-align: top;
        
        & > a {
            display: inline-block;
            color: #7a7a7a;
            text-transform: uppercase;
            position: relative;
            width: 100%;
            .transition(all);
            .transition-duration(250ms);
            font-weight: 500;
             
            &:after { 
                content: "";
                height: 2px;
                position: absolute;
                width: 100%; 
                left: 0;
                bottom: 0;
                .transition(all);
                .transition-duration(250ms);
                .scale(0);
            }
            
            @media (min-width: @screen-sm-min) {
                padding: 15px;
            }
            
            @media (max-width: @screen-sm-min) {
                padding: 15px 8px;
            }
        }
        
        &.active {
            & > a { 
                color: #000;
                
                &:after {
                    .scale(1);
                }
            } 
        }
    }
    
    &.tab-nav-right {
        text-align: right;
    }
    
    &.tn-justified {
        & > li {
            display: table-cell;
            width: 1%;
            text-align: center;
        }
    }
    
    &.tn-icon {
        & > li {
            .zmdi {
                font-size: 22px;
                line-height: 100%;
                min-height: 25px;   
            }
        }
    }
    
    &:not([data-tab-color]) {
        & > li > a:after {
            background: @m-blue;
        }
    }
    
    &[data-tab-color="green"] {
        & > li > a:after {
            background: @m-green;
        }
    } 
    
    &[data-tab-color="red"] {
        & > li > a:after {
            background: @m-red;
        }
    }
    
    &[data-tab-color="teal"] {
        & > li > a:after {
            background: @m-teal;
        }
    }
    
    &[data-tab-color="amber"] {
        & > li > a:after {
            background: @m-amber;
        }
    }
    
    &[data-tab-color="black"] {
        & > li > a:after {
            background: @m-black;
        }
    }
    
    &[data-tab-color="cyan"] {
        & > li > a:after {
            background: @m-cyan;
        }
    }
}

.tab-content {
    padding: 20px 0;
}