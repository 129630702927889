.revisions-table {
    .new-value {
        font-weight: bold;
        color: green;
        text-decoration: none;
    }

    .old-value {
        margin-left: .5em;
        text-decoration: none;
        font-style: italic;
        color: #999;
    }
}