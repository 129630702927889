.mejs-container {
  outline: none;

  .mejs-controls {
    background: #ec592f;
    height: 50px;
    padding: 10px 5px 0;

    div {
      height: 5px;
    }

    div.mejs-time-rail {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0;
      width: 100% !important;

      .mejs-time-total {
        margin: 0;
        width: 100% !important;
        background: #ec592f;
      }

      .mejs-time-loaded {
        background: #D04B25;
      }

      .mejs-time-current {
        background: #ffea00;
      }

      .mejs-time-buffering {
        background: #ec592f;
      }

      span:not(.mejs-time-float), a {
        border-radius: 0;
        height: 3px;
      }
    }



    .mejs-button {
      button {
        background-color: #ec592f;
        width: 15px;
        height: 15px;
        background-position: center;

        &:focus {
          outline: none !important;
        }
      }
    }
    .mejs-volume-button {
      position: absolute;
      right: 35px;
    }

    .mejs-play button {
      .img-retina('../img/icons/play.png', '../img/icons/play@2x.png', 15px, 15px);
    }

    .mejs-pause button {
      .img-retina('../img/icons/pause.png', '../img/icons/pause@2x.png', 15px, 15px);
    }

    .mejs-mute button {
      .img-retina('../img/icons/speaker.png', '../img/icons/speaker@2x.png', 15px, 15px);
    }

    .mejs-unmute button {
      .img-retina('../img/icons/speaker-2.png', '../img/icons/speaker-2@2x.png', 15px, 15px);
    }
 
    .mejs-fullscreen-button {
      position: absolute;
      right: 5px;
      button {
        .img-retina('../img/icons/fullscreen.png', '../img/icons/fullscreen@2x.png', 15px, 15px);
      }
    }
  }
}
