// Pulled icons
// -------------------------

.@{zmdi-icon-prefix} {
  &.pull-left {
    float: left;
    margin-right: .15em;
  }
  &.pull-right {
    float: right;
    margin-left: .15em;
  }
}
